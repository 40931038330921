import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import "antd/dist/reset.css";

import Router from "./router";
import i18n from "./translation";

const App = () => (
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

import { lazy } from "react";
import MinimalHeader from "../../components/MinimalHeader";
import ErrorPageContent from "../../content/ErrorPageContent.json";
import Footer from "../../components/Footer";

const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const WaveCanvas = lazy(() => import("../../components/WaveCanvas"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const Joined = () => {
  return (
    <>
    <MinimalHeader />
    <Container>
      <ScrollToTop />
      <MiddleBlock
        title={ErrorPageContent.title}
        content={ErrorPageContent.text}
        button={ErrorPageContent.button}
        id="slide"
      />
      <WaveCanvas
        frontWaveRGBColor="rgb(71, 85, 236, 0.1)"
        backWaveRGBColor="rgb(168, 168, 249, 0.1)"
      />
    </Container>
    <Footer />
    </>
  );
};

export default Joined;

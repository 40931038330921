import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Footer from "../components/Footer";
import routes from "./config";
import { Styles } from "../styles/styles";
import { Analytics } from "@vercel/analytics/react";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Analytics/>
      <RouterProvider router={createBrowserRouter(routes)} />
    </Suspense>
  );
};

export default Router;

import { Component, lazy } from "react";
import ErrorPage from "../pages/ErrorPage";
import { useRouteError } from "react-router-dom";
import Policy from "../pages/Policy";

export const sitemapExclusions = ["/sitemap", "/waitlist", "/home", "/waitlistjoined"];

const routes = [
  {
    path: "/",
    Component: lazy(() => import(`../pages/Home`)),
    errorElement: <ErrorBoundary />
  },
  {
    path: "/home",
    Component: lazy(() => import(`../pages/Home`)),
  },
  {
    path: "/sitemap",
    Component: lazy(() => import(`../pages/Sitemap`)),
  },
  {
    path: "/waitlist",
    Component: lazy(() => import(`../pages/Waitlist`)),
  },
  {
    path: "/waitlistjoined",
    Component: lazy(() => import(`../pages/Joined`)),
  },
  {
    path: "/policies",
    Component: lazy(() => import(`../pages/Policy`)),
  },
  {
    path: "/policies/:policy",
    Component: lazy(() => import(`../pages/Policy`)),
  }
];

function ErrorBoundary() {
  let error = useRouteError();
  return <ErrorPage />;
}

export default routes;
